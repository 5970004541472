import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/home.module.css";

const {div, h1, h2, p, br, li, ul, label, input} = require("hyperscript-helpers")(m);

const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page},
            div({class: style.mainview}, [
                h1("Packliste"),
                p("Da Tagungsort und Schlafplatz nicht in Laufweite sind und in den Bussen, die Euch vom Campus zur Turnhalle bringen kein Platz für Euer Gepäck sein wird, bitten wir Euch Tagungsmaterial und restliches Gepäck auf zwei Gepäckstücke zu verteilen und zu trennen. Das restliche Gepäck könnt Ihr direkt nach der Anmeldung abgeben, wir bringen es Euch dann direkt in die Turnhalle an Euren Schlafplatz."),
                p("Die (Gruppen)Duschen befinden sich in der Turnhalle."),
                h2("Tagungsgepäck (immer dabei):"),
                div(input({type:"checkbox", id:1}),label({for:1}," Enten")),
                div(input({type:"checkbox", id:2}),label({for:2}," Laptop + Ladekabel")),
                div(input({type:"checkbox", id:3}),label({for:3}," Handy + Ladekabel")),
                div(input({type:"checkbox", id:4}),label({for:4}," ggf. Powerbank")),
                div(input({type:"checkbox", id:5}),label({for:5}," Taschenlampe (für die dunkle Turnhalle)")),
                div(input({type:"checkbox", id:6}),label({for:6}," AK-Material (Selbstbericht)")),
                div(input({type:"checkbox", id:7}),label({for:7}," Mehrfachsteckdose")),
                div(input({type:"checkbox", id:8}),label({for:8}," Kabel für Beamer (USB-C oder HDMI)")),
                div(input({type:"checkbox", id:9}),label({for:9}," Flyer, Hefte, Publikationen (für Materialtisch)")),
                div(input({type:"checkbox", id:10}),label({for:10}," Schreibzeug")),
                div(input({type:"checkbox", id:11}),label({for:11}," Kuscheltiere")),
                div(input({type:"checkbox", id:12}),label({for:12}," Ausweisdokument (insb. für Exkursionen)")),
                div(input({type:"checkbox", id:13}),label({for:13}," ÖPNV-Ticket (sofern vorhanden)")),
                div(input({type:"checkbox", id:14}),label({for:14}," Immatrikulationsbescheinigung (Wie? Nocht nicht hochgeladen?...Dann aber schnell!)")),
                div(input({type:"checkbox", id:15}),label({for:15}," Tickets für die Hin- und Rückfahrt")),
                div(input({type:"checkbox", id:16}),label({for:16}," Bargeld (30 € Teilnehmendenbeitrag, Merch, Getränke - zur Sicherheit, wir wollen auf dieser ZaPF Kartenzahlung ausprobieren)")),
                div(input({type:"checkbox", id:17}),label({for:17}," Spaß und Freude")),
                div(input({type:"checkbox", id:18}),label({for:18}," kein Schlafdefizit")),
                div(input({type:"checkbox", id:19}),label({for:19}," Kuschelente")),
                div(input({type:"checkbox", id:20}),label({for:20}," Medikamente (sofern nötig)")),
                div(input({type:"checkbox", id:21}),label({for:21}," ggf. Führerschein")),
                div(input({type:"checkbox", id:22}),label({for:22}," Bücher/Spiele (können im Tagungsbüro gelagert werden)")),
                div(input({type:"checkbox", id:23}),label({for:23}," Trinkflasche (insb. für Exkursionen)")),
                div(input({type:"checkbox", id:24}),label({for:24}," Brotdose(n) (insb. für Exkursionen, An- und Abreise)")),
                br(),
                h2("Übernachtung/Hygiene (Abgabe nach der Anmeldung):"),
                div(input({type:"checkbox", id:25}),label({for:25}," Schlafzeug (Isomatte, Schlafsack, Decken, Kissen(!), etc.)")),
                div(input({type:"checkbox", id:26}),label({for:26}," Duschhandtuch")),
                div(input({type:"checkbox", id:27}),label({for:27}," Duschsachen (inkl. Badelatschen)")),
                div(input({type:"checkbox", id:28}),label({for:28}," Ohrstöpsel, ggf. Schlafmaske")),
                div(input({type:"checkbox", id:29}),label({for:29}," Kuschelenten")),
                div(input({type:"checkbox", id:30}),label({for:30}," Zahnbürste, Zahnpasta")),
                div(input({type:"checkbox", id:31}),label({for:31}," Deo, Hygieneartikel")),
                div(input({type:"checkbox", id:32}),label({for:32}," Bademantel")),
                div(input({type:"checkbox", id:33}),label({for:33}," Hausschuhe oder Hallenschuhe (für die Turnhalle)")),
                div(input({type:"checkbox", id:34}),label({for:34}," Hosen, T-Shirts, ...na Ihr wisst schon")),
                div(input({type:"checkbox", id:35}),label({for:35}," Regensachen (leichte Regenjacke je nach Witterung ggf. leicht abnehmbar)")),
                div(input({type:"checkbox", id:36}),label({for:36}," Medikamente (sofern nötig)")),
                div(input({type:"checkbox", id:37}),label({for:37}," Enten"))        
        ]))
    }
};

// Programm + Exkursionen => Programm
// Anmeldung + Merch + Packliste => Vor der ZaPF => nach oben
export default page;
