.hero {
    height: 100%
}

.hero img {
    float: right;
    height: 35vw;
    margin-left: 2em;
    margin-bottom: 2em;
    border-radius: 10px;
}

.memes{
    justify-content: space-around;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
}

.memes img{
    width: 25vw;
    padding-bottom: 1em;
}

@media only screen and (max-width: 800px) {
    .hero img {
        float: initial;
        width: 80vw;
        height: auto;
        margin: 0;
    }

    .memes img{
        width: 60vw;
    }

    .page {
        text-align: center;
    }

}