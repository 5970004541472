import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/home.module.css";

const {div, h1, h2, p, img, br, hr, a} = require("hyperscript-helpers")(m);

const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page},
            div({class: style.mainview}, [
                h1("Allgemeines"),
                p("ZaPF steht für Zusammenkunft aller deutschsprachigen Physikfachschaften und ist die Bundesfachschaftentagung (BuFaTa) der Physik. Sie findest einmal im Semester in einer immer wechselnden Stadt statt, dieses Mal in Mainz. Das Ziel der ZaPF ist ein inhaltlicher und sozialer Austausch zwischen den Unis über die Arbeit in den physikbezogenen Fachschaften, das Studium im Allgemeinen und alle anderen Probleme und Erfolge die Studierende heute beschäftigen.\n" +
                    "Themen die bei der Veranstaltung besprochen werden sind zum Beispiel:\n" +
                    "Austausch über Events und Organisation, Akkreditierung von Studiengängen, Inklusion in Studiengängen, Anti-Diskriminierung, Auswertung von Bachelor-, Master-, sowie Lehramtsstudiengangsumfragen, Digitalisierung und Angebot von Hybridformaten in der Lehre, Vernetzung der Studierenden, Gremienarbeit und andere hochschulpolitische Themen.\n" +
                    "\n" +
                    "Im Laufe der ZaPF werden Resolutionen und Positionspapiere erarbeitet, an relevante Adressaten weitergeleitet und bei Bedarf veröffentlicht.\n" +
                    "Zusätzlich werden alle Ergebnisse der ZaPF im Wiki veröffentlicht."),
                h1("Meine erste ZaPF"),
                h1("Die Orga"),])
        );
    }
};
export default page;
