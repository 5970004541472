.page {

}

.hero {
}

.hero img {
    width: 35vw;
    float: right;
    height: 35vw;
    margin-left: 2em;
    margin-bottom: 2em;
    border-radius: 10px;
}

.sponsor {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    background-color: white;
}

.sponsor img {
    height: 10vw;
    margin: 1em;
}


@media only screen and (max-width: 800px) {
    .hero img {
        display: none;
    }

    .sponsor img {
        width: 33vw;
        height: auto;

    }

    .page {
        text-align: center;
    }

}
