import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/about.module.css";

const {div, h1, ul, p, img, li, h2, a} = require("hyperscript-helpers")(m);

const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page},
            div({class: style.hero}, [
                h1("Weck, Woi und unsere Fachschaft"),
                img({src: require('/assets/img/fsmainz.jpeg')}),
                p("Wir heißen alle ZaPFika in Mainz willkommen"),
                p("Unsere Fachschaft zeichnet sich durch viele Veranstaltungen aus. Unser Veranstaltungskalender ist prall gefüllt, das bedeutet viel Arbeit, aber mit all unseren tollen Mitgliedern schaffen wir es, die Arbeit gut zu verteilen."),
                p("Selbstverständlich setzen wir uns auch für die Belange der Studierenden ein und suchen bei Bedarf das Gespräch mit den Dozierenden."+
                    "Wir haben auch ein sehr produktives und freundschaftliches Verhältnis zu unserem Institut. Wir pflegen es aktiv und schätzen die kurzen Wege bei Problemen. Dafür  "),
            ]),
            h1({},"#Mainz"),
            p("Mainz hat viel zu bieten! Seien es Sehenswürdigkeiten wie der Mainzer Dom oder die römischen Funde, die sich über die ganze Stadt verteilen, oder kulturelle Veranstaltungen wie die Fasenacht, das Marktfrühstück und viele Feste und Feiern das ganze Jahr über."),
            p(""),
            h1("Unsere Institute"),
            h2(a({href:"https://www.kernphysik.uni-mainz.de/wir-ueber-uns/"},"Institut für Kernphysik")),
            p("Die Forschung am Institut für Kernphysik dreht sich um die Untersuchung der Grundbausteine der Materie, insbesondere um ein Verständnis der sogenannten starken Kraft und ihrer Konsequenzen für die Kern- und Teilchenphysik."),
            ul([li(a({href:"https://www.kernphysik.uni-mainz.de/beschleuniger/mainzer-mikrotron/"},"MAMI (Mainzer Mikroton)")),
                li(a({href:"https://www.mesa.uni-mainz.de"},"MESA (Mainz Energy-Recovering Superconducting Accelerator)"))]),
            h2(a({href:"https://www.iph.uni-mainz.de/ueber-uns/"},"Institut für Physik")),
            p("Wir untersuchen eine breite Palette von Themen von der Grundlagen- bis zur anwendungsorientierten Forschung, u.a. auf den Gebieten: Astro-Teilchenphysik, Bildgebende Verfahren, Detektorenentwicklung, Dunkle Materie, Eichtheorien, Elementarteilchenphysik, Festkörperphysik, Halbleiterphysik, Hochenergiephysik, Ionenfallen, Kolloidphysik, Laserspektroskopie, Magnetismus, Neutrinophysik, Neutronenphysik, Oberflächenphysik, Quantenoptik, Quanteninformation, Statistische Physik, Weiche Materialien, Ultrakalte Ionen, Ultrakalte Neutronen, Vielteilchensysteme."),
            h2(a({href:"https://www.ipa.uni-mainz.de"},"Institut für Physik der Atmosphäre")),
            p("In 6 Arbeitsgruppen (Theoretische Meteorologie, Theoretische Wolkenphysik, Erdsystemmodellierung, Partikelchemie, Experimentelle / Beobachtende Meteorologie, Atmosphärische Spurengase) werden verschiedenste Prozesse und Zusammenhänge im täglichen Wettergeschehen sowie auf klimatologisch relevanten Skalen erforscht. Im Zusammenspiel zwischen theoretischen Modellen und experimentellen Methoden wird durch die Forschung am Institut für Physik der Atmosphäre das Verständnis für die Atmosphäre verbessert. Die Lehre vermittelt die dazu notwendigen mathematisch-physikalischen Grundlagen sowie das Spezialwissen in theoretischer und experimenteller Meteorologie."),
            h1("Lokale Sehenswürdigkeiten"),
            a({href:"https://www.ndr.de/fernsehen/sendungen/extra_3/videos_daserste/Realer-Irrsinn-Bus-Bruecke-ohne-Strassenanschluss,extra10698.html"},"Bus-Brücke ohne Straßenanschluss")," ",
            a({href:"https://www.campus-mainz.net/newsdetails/aenderungen-im-campus-oepnv-zum-wintersemester-1"}," (Ende 2019 hat die den Namen verloren)"),
            h1("Memes"),
            div({class: style.memes}, [
                img({src: require('/assets/img/memes/11.jpeg')}),
                img({src: require('/assets/img/memes/10.jpeg')}),
                img({src: require('/assets/img/memes/9.jpeg')}),
                img({src: require('/assets/img/memes/8.jpeg')}),
                img({src: require('/assets/img/memes/7.jpeg')}),
                img({src: require('/assets/img/memes/6.jpeg')}),
                img({src: require('/assets/img/memes/5.jpeg')}),
                img({src: require('/assets/img/memes/4.jpeg')}),
                img({src: require('/assets/img/memes/3.jpeg')}),
                img({src: require('/assets/img/memes/2.jpg')}),
                img({src: require('/assets/img/memes/1.jpg')}),]
            )
        );
    }
};
export default page;
