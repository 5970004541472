import * as m from "mithril";
import PageTemplate from "../components/PageTemplate";
import style from "/assets/css/pages/home.module.css";

const {div, h1, h2, p, img, br, hr, a} = require("hyperscript-helpers")(m);

const page: m.Component = {
    view() {
        return m(PageTemplate, {css: style.page},
            div({class: style.mainview}, [
                h1("Programm"),
                p("folgt noch"),
                h1("Exkursionen"),
                p("folgt noch")
            ]
        ))
    }
};
export default page;
